.company-list-group {
  &.existing-companies {
    max-height: 200px !important;
    overflow-y: scroll;
  }

  .list-group-item {
    padding: 1rem 1.25rem;
    border-top: solid 2px $light-grey-200;
    border-left: solid 2px $light-grey-200;
    border-right: solid 2px $light-grey-200;
    color: $dark-grey;
    cursor: pointer;

    &.selected {
      color: $dark;
    }

    &.appear-selected {
      color: $dark;
    }

    &:last-child {
      border-bottom: solid 2px $light-grey-200;
    }

    &.action-not-clickable {
      cursor: default;
    }

    img {
      max-width: 48px;
      max-height: 48px;
    }

    p {
      margin: 0;
    }

    i {
      color: $dark-grey;
      margin: 1.25rem 0;
      font-size: 0.85rem;
    }

    .company-image-padding {
      max-width: 48px;
      max-height: 48px;
      min-width: 48px;
      min-height: 48px;
    }

    .action-title {
      font-weight: bold;
      font-size: 1.25rem;
    }

    .action-description {
      color: $dark-grey;
      font-size: 0.85rem;
      margin-top: -2px;
    }

    .custom-selector {
      position: absolute;
      cursor: pointer;
      height: 0;
      width: 0;
      left: 18px;
    }

    input[type="radio"] {
      -moz-appearance: initial;
    }

    .custom-selector:after {
      content: "";
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $light;
      line-height: 48px;
      text-align: center;
      vertical-align: center;
      left: -18px;
      position: absolute;
      z-index: 1;
    }

    .custom-selector:checked:after {
      font-family: "Font Awesome 5 Free";
      background-color: $primary;
      font-weight: 600;
      font-size: 0.75rem;
      color: $white;
      content: "\f00c";
    }
  }
}
