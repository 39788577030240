.card-body table thead th {
  border-top: 0;
}

.bs-searchbox {
  &:after {
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    color: #888888;
    vertical-align: middle;
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    right: 10px;
  }

  input.form-control {
    padding-top: 0 !important;
    margin-bottom: 10px !important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px !important;
    min-width: 100%;
    border-bottom: 2px solid #e0e0e0 !important;

    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

table.table {
  border-collapse: separate;
  border-spacing: 0;

  // Add row padding by inserting a fake cell,
  // allows full row highlighting but shorter border
  tr {
    // Remove default striped styling
    &.odd,
    &.even {
      background-color: transparent;
    }

    &:before {
      content: "";
      width: 20px;
      display: table-cell;
      background-color: transparent;
    }

    &:after {
      content: "";
      width: 20px;
      display: table-cell;
      background-color: transparent;
    }
  }

  &.table-borderless tbody {
    box-shadow: none;
    background-color: transparent;
    border-radius: unset;
  }

  &.table-borderless tr td {
    border-top: none;
  }

  tbody {
    background-color: $white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    border-radius: $table-border-radius;

    tr {
      vertical-align: middle;

      td {
        border-top: solid 1px $gray-200;
        font-size: 0.875rem;
        color: $dark-grey;

        &.label-cell {
          color: $black;
          font-size: 1rem;
        }

        .btn {
          font-size: 0.875rem;
        }

        &:first-of-type {
          padding-left: 12px;
        }

        &:last-of-type {
          padding-right: 12px;
        }
      }

      &:first-of-type {
        &:before {
          border-top-left-radius: $table-border-radius;
        }

        &:after {
          border-top-right-radius: $table-border-radius;
        }

        td {
          border-top: none;
        }
      }

      &:last-of-type {
        &:before {
          border-bottom-left-radius: $table-border-radius;
        }

        &:after {
          border-bottom-right-radius: $table-border-radius;
        }
      }
    }
  }

  @each $color, $value in $theme-colors {
    @include theme-table-row-variant(
      $color,
      theme-color-level($color, $table-bg-level),
      theme-color-level($color, $table-border-level)
    );
  }

  th {
    color: $dark-grey;
    font-size: 0.875em;
  }

  th.sortable {
    &:after {
      font-weight: bold;
      content: "";
      font-size: 0.75em;
      margin-left: 0.3rem;
      display: none;
      vertical-align: top;
      margin-top: 2px;
    }

    &:hover {
      background-color: transparent;
      color: $dark;

      &:after {
        text-decoration: none;
      }
    }

    &.asc,
    &.desc {
      color: $primary;

      &:after {
        color: $primary;
      }

      &:hover {
        color: darken($primary, 10%);

        &:after {
          color: darken($primary, 10%);
        }
      }
    }

    &.asc:after {
      content: "↑";
      display: inline-block;
    }

    &.desc:after {
      content: "↓";
      display: inline-block;
    }
  }

  th.pseudo-sortable {
    &:after {
      font-weight: bold;
      content: "";
      font-size: 0.75em;
      margin-left: 0.3rem;
      display: inline-block;
      vertical-align: top;
      margin-top: 2px;
    }

    &:hover {
      background-color: transparent;
      color: $dark;

      &:after {
        text-decoration: none;
      }
    }

    &.asc,
    &.desc {
      color: $primary;

      &:after {
        color: $primary;
      }

      &:hover {
        color: darken($primary, 10%);

        &:after {
          color: darken($primary, 10%);
        }
      }
    }

    &.desc:after {
      content: "↑";
    }

    &.asc:after {
      content: "↓";
    }
  }
}

// Sortable table panel - avoid visualizing as regular card
.card.em-sortableTable-panel {
  border: none;

  .card-header,
  .card-body,
  .card-footer {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .pager-form {
    margin-top: 0.75rem;
    color: $gray-600;
    font-size: 0.875rem;
    font-weight: normal;

    span {
      margin-right: 0.25rem;
    }

    .form-control {
      font-size: 0.875rem;
      padding: 0;
      width: 34px;
      height: calc(1em + 0.75rem + 2px);
      line-height: 1;
    }

    .no-btn {
      width: 31px;
      margin-right: -0.25rem;
    }

    .btn {
      font-size: 0.75rem;
      width: 34px;
      padding: 0;
    }
  }
}

//table hovering
table.table:not(.no-hover):not(.grouped-table).em-sortableTable-table tbody tr:not([data-summary])[up-href] {
  &:hover {
    cursor: pointer;

    &:first-of-type {
      td,
      &::after {
        padding-top: 10px;
      }
    }

    td {
      padding-top: 11px;
      padding-bottom: 10px;
      border-top: solid 2px $primary;
      border-bottom: solid 2px $primary;

      &:first-of-type {
        padding-left: 10px;
        border-left: solid 2px $primary;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-of-type {
        padding-right: 10px;
        border-right: solid 2px $primary;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

//grouped-table-hovering
table.table:not(.no-hover).em-sortableTable-table tbody tr.selected,
table.table:not(.no-hover).em-sortableTable-table tbody tr.hovered {
  cursor: pointer;

  &:first-of-type {
    td:not([rowspan]),
    &::after {
      padding-top: 10px;
    }
  }

  td:not([rowspan]) {
    &:first-of-type {
      padding-left: 10px;
      border-left: solid 2px $primary;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.pseudo-first {
      padding-left: 10px;
      border-left: solid 2px $primary;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      padding-right: 10px;
      border-right: solid 2px $primary;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    padding-top: 11px;
    padding-bottom: 10px;
    border-top: solid 2px $primary;
    border-bottom: solid 2px $primary;
  }
}

table > tbody > tr[data-summary-url] > td:first-child:before {
  content: none;
}
