.asset-details-blade-wrapper {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE */

  .asset-detail-text {
    font-size: 0.875rem;
  }

  .asset-actions-container {
    background-color: #f4f2f4;

    #assetActionDropdownMenu::after {
      float: right;
      vertical-align: middle;
      line-height: 20px;
      font-family: "Font Awesome 5 Pro";
      content: "\f107";
      font-weight: 400;
    }
  }

  #tabs {
    .type-required {
      border-bottom: 2px solid #c010c0;
    }

    select.form-control[size] {
      height: 40px;
      min-width: 120px;
    }

    .editable-value-control {
      input,
      textarea,
      .dropdown-toggle {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-bottom: 2px solid #c010c0;
        background-color: transparent;
        min-width: 200px;

        &:focus {
          box-shadow: none !important;
          outline: none !important;
        }
      }
    }
  }

  .js-audio-detail-button {
    right: 0;
    margin-top: 16px;
    position: absolute;
  }
}

.asset-details-blade-wrapper::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.em-view-listView {
  .form-group.active {
    background-color: $primary !important;

    label {
      color: $white;
    }

    &::after {
      color: $white;
    }

    .btn.dropdown-toggle,
    input {
      border: none;

      * {
        color: $white;
      }
    }
  }

  .fa-comment {
    color: $blue;
  }

  .fa-flag {
    color: $red;
  }
}

.red-flag {
  color: $red;
}

.check-mark-column {
  min-width: 2rem;
}

#bulkActionForm thead {
  white-space: nowrap;
}

.tabbable.filled {
  margin-bottom: 0 !important;
}

#tabs {
  background-color: #f4f2f4;
  width: 139%;
  transform: translate(-13%);
  min-height: 53vh;

  @include media-breakpoint-down(sm) {
    & {
      width: 100%;
      transform: translate(0);
    }
  }

  .tab-content {
    margin-left: 5%;

    form {
      margin-bottom: 0 !important;

      label {
        font-size: 0.875rem;
      }
    }
  }

  .nav-tabs {
    padding-left: 8%;
    background-color: white;
  }

  .pcs-body-section {
    padding-top: 2rem;
  }

  .nav-tabs .nav-item .nav-link {
    font-family: TTCommons;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #888888;

    &.active {
      color: #242424;
    }
  }

  .col-md-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;

    .form-group {
      display: flex;
      flex-wrap: wrap;

      label {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        color: #888888;
      }

      .form-control-static {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        padding-bottom: 10px;
        margin-bottom: 0;
      }

      input,
      .bootstrap-tagsinput {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        flex-wrap: wrap;
      }

      .bootstrap-tagsinput {
        background-color: transparent !important;
      }
    }
  }

  #btn-save,
  #btn-cancel {
    display: none;
  }

  .bootstrap-tagsinput {
    padding: 12px 0px;

    .label-info {
      &:first-of-type {
        margin-left: 0;
      }

      border-radius: 12px;
      background-color: #e0e0e0;
      font-family: TTCommons;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #888888;

      [data-role="remove"] {
        color: #888888;
      }
    }
  }

  span.editable-value-value {
    color: #212529;
  }

  span.editable-value-wrapper {
    color: #888888;
  }

  #properties\[asset\]\.initializedValue\.label {
    font-family: TTCommons;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #c010c0;
  }

  span.twitter-typeahead {
    span.tag.label.label-info {
      font-family: "Font Awesome 5 Pro";
      content: "\f067";
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      border-radius: 14px;
      background-color: #03a9f4;
      padding: 0 0.5em 0;
    }
  }
}

.wave-play-button {
  .play,
  .pause {
    display: none;

    &.show {
      display: block;
    }
  }
}

.validation-modal-width {
  max-width: 650px;
  width: 650px;
}

@media only screen and (max-width: 800px) {
  .js-audio-detail-button {
    visibility: hidden;
  }
}
