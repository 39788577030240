.blade-container {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  z-index: 1000;
  position: absolute;
  right: 250px;
  width: 0;
  height: 100%;
  margin-right: -250px;
  overflow-y: auto;
  background: $white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &.sticky {
    position: fixed;
    top: 0;
  }

  .form-group label {
    color: $dark-grey;
  }

  h3 .editable-value-form input.form-control {
    max-width: 23rem;
  }
}

.blade-wrapper {
  width: 440px;
}

.blade-content {
  padding: 20px;
}

.show-blade {
  .blade-container {
    width: 440px;
  }
}

.right-blade-container {
  &.show {
    width: 480px;

    @include media-breakpoint-down(sm) {
      & {
        width: 100%;
      }
    }
  }

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  z-index: 1000;
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  background: $white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE */
  .asset-details-wrapper {
    position: relative;
    top: 36px;
    left: 50%;
    transform: translate(-50%);
    width: 360px;

    @include media-breakpoint-down(sm) {
      & {
        padding: 1rem;
        width: 100vw;
      }
    }

    .video-js {
      border-radius: $border-radius;

      .vjs-poster,
      .vjs-tech {
        border-radius: $border-radius;
      }
    }

    .wave-surfer {
      // height of the wave-form (200px + timeline height), so the content doesn't jump down when te waveform is loading slowly
      height: 290px;

      .spinner-border {
        float: right;
        margin-top: 100px;
        margin-right: 150px;
      }
    }
  }
}
