.em-list-form {
  margin-top: 2rem !important;
  margin-bottom: 1.5rem !important;
}

.entity-query-filter-form {
  padding-bottom: 1.5rem;

  .form-group {
    margin-top: 80px;
    position: relative;
    background-color: white;
    border-radius: 8px;

    &:hover {
      background-color: $light-grey-200 !important;
    }

    input,
    .input-group {
      font-size: 16px;
      outline: 0;
      border-radius: 8px !important;
      padding-bottom: 0;
    }

    input {
      background-color: transparent !important;
      padding-top: 10px;
    }

    .dropdown,
    button {
      border-radius: 8px !important;
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    label {
      position: absolute;
      color: $dark-grey;
      font-size: 16px;
      transform-origin: top left;
      transform: translate(12px, 0px) scale(1);
      transition: all 0.1s ease-in-out;
      pointer-events: all;
    }

    &.active,
    &.focus {
      label {
        transform: translate(12px, -8px) scale(0.7);
      }

      .filter-option-inner {
        display: inline-block;
      }
    }

    .filter-option {
      margin-bottom: -11px;
    }

    .filter-option-inner {
      display: none;
    }

    .filter-option-inner-inner {
      color: $black;
    }
  }
}

.form-label-group {
  position: relative;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: $dark-grey;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group > input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
  //border: 5px solid red;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
