.em-view-listView[data-em-entity="campaignAsset"] {
  .partial-loading {
    &::before {
      color: $dark-grey;
      content: "\f1ce";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
      position: absolute;
      left: 50%;
      top: 80px;
      font-size: 3.5rem;
      text-align: center;
      background-size: cover;
      z-index: 1000;
    }
  }
}

#linkAssetToCampaignModal {
  .modal-body:empty {
    min-height: 100px;

    &::before {
      color: $dark-grey;
      content: "\f1ce";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      font-size: 3.5rem;
      text-align: center;
      background-size: cover;
      z-index: 1000;
    }
  }
}

#bulk-unlink-asset.spinner.show {
  position: relative;

  .fa-unlink {
    visibility: hidden;
  }

  &::before {
    color: $dark-grey;
  }
}

.spinner.show {
  position: relative;

  &::before {
    color: $light-grey-100;
    content: "\f1ce";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    position: absolute;
    left: 15%;
    text-align: center;
    background-size: cover;
    z-index: 1000;
  }
}

.spinner-center.show {
  position: relative;

  &::before {
    color: $light-grey-100;
    content: "\f1ce";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    position: absolute;
    left: 50%;
    bottom: 25%;
    text-align: center;
    background-size: cover;
    z-index: 1000;
  }
}

.editable-value-form.spinner span[data-editable-value-control="true"] {
  &::before {
    color: $primary;
    content: "\f1ce";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    position: absolute;
    text-align: center;
    background-size: cover;
    z-index: 1000;
    top: calc(50% - 12px);
    right: 5%;
  }

  select:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }

  input:disabled {
    background-color: $light-grey-100 !important;
    color: $dark-grey !important;
    border-color: $dark-grey !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .js-form-datetimepicker {
    .input-group-append {
      .input-group-text {
        border-bottom: 2px solid $dark-grey;

        .fa-calendar {
          visibility: hidden;
        }
      }
    }
  }
}
