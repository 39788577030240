.landing-wrapper {
  .background-image {
    position: absolute;
    top: 8rem;

    img {
      max-height: 100vh;
    }
  }

  .welcome-info-wrapper {
    position: absolute;
    right: 15%;
    top: 200%;

    h1 {
      font-size: 64px;
      font-weight: 600;
      line-height: 1;
    }

    p {
      font-size: 20px;
    }

    .btn {
      width: 65%;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }
  }
}

.login-modal,
.registration-modal,
.registration-flow-modal,
.registration-big-modal,
.password-forgot-modal,
.password-reset-modal {
  .modal-content {
    flex-direction: row;
    min-height: 806px;
    max-height: 806px;
  }

  @include media-breakpoint-down(sm) {
    .modal-content {
      min-height: 80vh;
    }
  }

  h1 {
    font-size: 2rem;
  }

  .field-icon {
    float: right;
    margin-top: -35px;
    margin-right: 5px;
    position: relative;
    z-index: 2;
  }

  .btn {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }

  /*.btn-primary{
    background-image: radial-gradient(circle at 5% 4%, #c010c0, rgba(192, 16, 192, 0) 98%)
  }*/

  .text-secondary {
    color: $dark-grey !important;
    font-weight: normal;

    &.text-link {
      color: $secondary !important;
    }
  }

  .sub-title {
    font-size: 1.2rem;
  }

  .form-control {
    &::placeholder {
      color: $dark-grey;
      font-weight: normal;
    }
  }

  .error-text {
    display: block;
    font-size: 0.85rem;
    line-height: 0.9rem;
    margin-bottom: 0.1rem;
    padding-left: 5px;
  }

  .custom-select {
    -webkit-appearance: none;
    color: $dark-grey;

    option:disabled {
      color: $light-grey-200;
    }
  }

  .create-organisation-button {
    cursor: pointer;
  }
}

.registration-completed-modal {
  h1 {
    font-size: 4rem;
  }

  .confirmation-to-email {
    color: $secondary;
    width: 100%;
    display: block;
  }

  p {
    color: $dark-grey !important;
    font-weight: normal;
    font-size: 1.25rem;
  }

  .instruction {
    span {
      color: $secondary;
    }
  }

  .btn {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }
}

.redirect-btn {
  font-weight: normal;
  color: $dark-grey;
}

.login-redirect-btn,
.register-redirect-btn {
  display: inline !important;
  vertical-align: unset !important;
  font-weight: bold;
  border: none;
}

@media only screen and (max-width: 1200px) {
  .landing-wrapper {
    .background-image {
      img {
        max-width: 40vw;
      }
    }

    .welcome-info-wrapper {
      top: 150%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .landing-wrapper {
    .background-image {
      display: none;
    }

    .welcome-info-wrapper {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 150%;
      width: fit-content;
    }
  }
}

@include media-breakpoint-down(sm) {
  .landing-wrapper {
    .welcome-info-wrapper {
      top: 120%;
    }
  }
}
