#linkAssetToCampaignModal {
  input[type="checkbox"] {
    -moz-appearance: initial; // This is needed for Firefox Browsers to make ::after and ::before work
  }

  .modal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #linkAssetToCampaignModalTitle {
    margin-top: 1.5rem;
    font-size: 64px;
  }

  .modal-buttons {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0px -15px 10px -15px rgba(0, 0, 0, 0.08);

    button {
      max-width: 13rem;
      padding: 1rem 1.5rem;
    }
  }

  .search-input {
    position: relative;

    input {
      height: 50px;
      padding-left: 20px;
    }

    &::after {
      font-family: "Font Awesome 5 Free";
      content: "\f002";
      color: #888;
      vertical-align: middle;
      font-weight: 600;
      font-size: 0.85rem;
      position: absolute;
      right: 20px;
      top: 15px;
      bottom: auto;
    }
  }
}

.campaign-list-group {
  max-height: 20rem;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE */

  li {
    list-style: none;
  }

  .list-group-item {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 1rem 1.25rem 1rem 0;
    color: $dark-grey;
    cursor: pointer;

    &.selected {
      color: $dark;
    }

    &.appear-selected {
      color: $dark;
    }

    &:last-child {
      border-bottom: solid 2px $light-grey-200;
    }

    &.action-not-clickable {
      cursor: default;
    }

    img {
      max-width: 85px;
      height: 48px !important;
      max-height: 48px;
      border-radius: 12px;
      object-fit: cover;
    }

    p {
      margin: 0;
    }

    i {
      color: $dark-grey;
      margin: 1.25rem 0;
      font-size: 0.85rem;
    }

    .campaign-image-padding {
      max-width: 48px;
      max-height: 48px;
      min-width: 48px;
      min-height: 48px;
    }

    .action-title,
    .campaign-action-title {
      font-weight: bold;
    }

    .action-title {
      span.cancelled-campaign {
        text-decoration: line-through;
        opacity: 48%;
        display: inline-block;
        max-width: 14.5rem;
        overflow: clip;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .campaign-action-title {
      margin: 0.5rem 0;
    }

    .action-description {
      color: $dark-grey;
      font-size: 0.85rem;
      margin-top: -2px;

      span.cancelled-campaign {
        text-decoration: line-through;
      }
    }

    .custom-selector {
      position: absolute;
      cursor: pointer;
      height: 0;
      width: 0;
      right: 18px;
    }

    input[type="radio"] {
      -moz-appearance: initial;
    }

    .custom-selector:after {
      content: "";
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $light;
      line-height: 48px;
      text-align: center;
      vertical-align: center;
      left: -45px;
      position: absolute;
      z-index: 1;
    }

    .custom-selector:checked:after {
      font-family: "Font Awesome 5 Free";
      background-color: $primary;
      font-weight: 600;
      font-size: 0.75rem;
      color: $white;
      content: "\f00c";
    }
  }
}

.campaign-list-group::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.create-new-campaign-img {
  max-width: 56px !important;
}

.campaign-list-group {
  label.list-group-item.cancelled-campaign {
    cursor: default;

    input {
      cursor: default;
    }
  }
}
