#toast-container.toast-bottom-center > div,
#toast-container.toast-top-center > div {
  min-width: 30%;
  right: 0;
  margin-right: 20px;
  border-radius: 8px;
  text-align: center;
}

.toast-top-center {
  top: 25px;
}

#toast-container > div {
  opacity: 1;
  padding: 10px 15px 8px 50px;
}

#toast-container > .toast-success {
  background-image: none !important;
  background-color: $success;
}

.toast-success {
  .toast-message {
    &::before {
      font-family: "Font Awesome 5 Free";
      content: "\f00c";
      color: $white;
      vertical-align: middle;
      font-weight: 600;
      margin-right: 10px;
    }
  }
}
