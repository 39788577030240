:root {
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding-top: 4.5rem;
}

.main {
  padding: 1rem;
}

@include media-breakpoint-up(sm) {
  .main {
    padding: 1rem 2rem 2rem 2rem;
  }
}

@include media-breakpoint-up(lg) {
  .main {
    padding: 1rem 3rem 3rem 4rem;
  }
}
/**
 * Page header.
 */
// quickfix: hide logo
.form-signin .page-header {
  background: none !important;
}

.pcs-header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 1rem;

  .page-header {
    line-height: 0.75;
    flex-grow: 2;

    small {
      margin-top: 1.2rem;
      display: block;

      &:empty {
        display: none;
      }
    }
  }
}

//content body
#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 0;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 350px;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}
