.password-strength-view {
  position: absolute;
  right: 25px;
  top: 50%;
  margin-top: -15px;

  span {
    margin-right: 2px;
    font-size: 1.25rem;
    font-weight: bold;

    &.text {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}
