@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";

/*
!* Bootstrap variables *!
*/

// Colors
$primary: #c010c0;
$info: #bbbbbb;
$white: #ffffff;
$danger: #ff5050;
$light: #f4f2f4;
$success: #4caf50;
$secondary: #03a9f4;
$warning: #ff9800;
$dark: #242424;
$light-grey-100: #f5f5f5;
$light-grey-200: #e0e0e0;
$light-grey-300: #c9c9c9;
$dark-grey: #888888;
$black: #000000;

$body-bg: $white;
$body-color: $dark;

// Breadcrumb
$breadcrumb-active-color: $dark;
$breadcrumb-divider-color: $dark-grey;
$breadcrumb-bg: $light-grey-100;
$breadcrumb-divider: quote(">");
$breadcrumb-padding-x: 0;
$breadcrumb-border-radius: 0;

$modal-backdrop-bg: $primary;

$tooltip-bg: $dark-grey;
$tooltip-opacity: 1;

$border-radius: 8px;

$font-family-base: "TTCommons", sans-serif !default;
$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;

$input-font-weight: $font-weight-base;

// Card
$card-bg: transparent;

// Table
$table-border-width: 0;
$table-border-radius: 10px;
$table-hover-bg: transparent;
$table-bg-level: -11;
$table-border-level: 0;

// Checkbox
$custom-control-indicator-bg: $light-grey-100;

// Buttons
$btn-border-radius: 0.5rem;
$btn-border-radius-lg: 0.5rem;
$btn-border-radius-sm: 0.5rem;

// Dropdown
$dropdown-header-color: #000;

@import "~bootstrap/scss/variables";
