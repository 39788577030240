.bootstrap-select .dropdown-menu li a span.check-mark {
  margin-left: -10px;
  top: 0;
  height: 1rem;
  width: 1rem;
  background-color: $light-grey-100;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid $light-grey-200;
  margin-right: 10px;

  &:hover {
    background-color: $light-grey-200;
  }
}

.editable-value-control .bootstrap-select .dropdown-menu li a span.check-mark {
  display: none;
}

#bs-select-1-0 {
  min-height: 32px;
}

.bootstrap-select .bs-ok-default:after {
  display: none;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: unset;
  background-color: $primary;
  border-color: $primary;

  &::after {
    height: 0.6em;
    color: $white;
    display: block;
    margin-left: 3px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  position: relative;
  top: -2px;
}
