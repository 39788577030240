.em-form.up-active [type="submit"].btn-primary {
  position: relative;
  color: $primary;
  background-color: darken($primary, 7.5%);
  border-color: darken($primary, 7.5%);

  &:after {
    color: $light-grey-100;
    content: "\f1ce";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    background-size: cover;
  }
}

.nav-tabs {
  .nav-item:first-of-type .nav-link.up-active::after {
    left: 0;
    width: calc(100% - 17px);
  }

  .nav-link.up-active {
    color: $black;
    position: relative;
    border-bottom: 1px solid #dee2e6;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 0;
      width: calc(100% - 30px);
      background: $dark-grey;
      animation-name: grey-pulse;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      height: 4px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      margin-bottom: -0.5px;
    }
  }
}

.sidebar .list-group-item-action.up-active:not(.list-group-subgroup-toggle) {
  position: relative;

  &:after {
    content: "\f1ce";
    color: $dark-grey;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    position: absolute;
    right: 0.5rem;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
  }
}

@keyframes grey-pulse {
  0% {
    background-color: $dark-grey;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: $dark-grey;
  }
}
