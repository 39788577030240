/**
 * Contains the admin web specific theme css, to be applied after module extension CSS.
 */
@import "~/node_modules/video.js/dist/video-js.css";
@import "~/node_modules/unpoly/dist/unpoly.css";

@import "bootstrap/variables";
@import "theme/fonts";
@import "theme/mixins";
@import "theme/page";
@import "theme/typography";
@import "theme/adminweb";
@import "theme/unpoly";
@import "theme/shop";
@import "theme/components/sidebar";
@import "theme/components/breadcrumb";
@import "theme/components/multiselect";
@import "theme/components/topnav";
@import "theme/components/navtabs";
@import "theme/components/blade";
@import "theme/components/datetimepicker";
@import "theme/components/partial";
@import "theme/components/buttons";
@import "theme/components/dialog";
@import "theme/components/toast";
@import "theme/components/search-form";
@import "theme/components/floatinginputfield";
@import "theme/components/create-campaign";
@import "theme/components/progressbar";
@import "theme/components/asset";
@import "theme/components/bootstrap-tagsinput.css";
@import "theme/components/bottomblade";
@import "theme/components/filter-blade";
@import "theme/components/backgroundoverlay";
@import "theme/components/videojs";
@import "theme/components/landingspage";
@import "theme/components/landingspage-legacy";
@import "theme/components/tagsinput";
@import "theme/components/pane";
@import "theme/components/asset-library";
@import "theme/components/activity-timeline";
@import "theme/components/registration";
@import "theme/components/campaignlistgroup";
@import "theme/components/companylistgroup";
@import "theme/components/checkmark";
@import "theme/components/passwordstrengthcalculator";
@import "theme/components/management-form";
@import "theme/components/read-form";
@import "theme/components/campaign";
@import "theme/components/listview";
@import "theme/components/tiny-mce";
@import "theme/components/mark-asset";
@import "theme/components/spinner";
@import "theme/components/ghost";
@import "theme/components/audio-details";
@import "theme/components/asset-tracker-modal";
@import "theme/components/modal";
@import "theme/components/navbar";
@import "theme/components/side-navbar";
@import "theme/components/breadcrumb";
@import "theme/components/company-switch-warning";
// specific adminwebpages
@import "pages/admin/terms-and-conditions";

.custom-checkbox .custom-control-label::before {
  border-radius: 4px;
}

.text-dark-grey {
  color: $dark-grey !important;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item span.text {
  max-width: 30rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.bootstrap-select .dropdown-menu ul.dropdown-menu.inner.show {
  margin-bottom: 0 !important;
}

.bootstrap-select .dropdown-menu {
  max-width: 33rem !important;
}

.font-sm {
  font-size: 0.875rem;
}

.font-lg {
  font-size: 1.25rem;
}

.font-4x {
  font-size: 4rem;
}

select.form-control {
  height: calc(1.5em + 0.75rem + 2px) !important;
}

.list-style-none {
  list-style: none;
}

body:not(.mce-content-body) {
  padding-top: 0;
  background-color: #f4f2f4;
}

.fit-content {
  max-width: fit-content;
}

div[data-em-property="sortIndex"] .numeric {
  max-width: 80px;
}

div[data-em-property="workflowActionFields[].options"] {
  margin-left: 2rem;
}

.list-header-actions {
  margin-left: auto;
  margin-right: 0;
}

div[draggable]:hover {
  cursor: pointer;
}

.navbar-dark {
  background-color: $black;
}

#errorModal {
  z-index: 2147483647;

  .modal-dialog {
    max-width: 400px !important;
  }
}

.greeting {
  font-size: 4rem;
  width: 50%;
  line-height: 1;

  @include media-breakpoint-down(sm) {
    & {
      width: 100%;
    }
  }
}

.greeting-subtitle {
  color: $dark;
  font-size: 20px;
  font-weight: 500;
}

.dropdown-menu {
  z-index: 1001;
}

.dropdown-toggle {
  min-height: 40px;
}

.fa-comment-alt {
  font-size: 15px;
}

.text-secondary {
  color: $dark-grey !important;
}

/*
 todo: for consistency, we might want to remove the text-secondary above and keep the default we've defined in scss/bootstrap/_variables.scss
       this wil require a bit refactoring though to fix the current use of text-secondary
 */
.text-light-blue {
  color: $secondary !important;
}

#entity.amount {
  width: 90%;
}

a.dropdown-item {
  line-height: 24px;
}

form {
  .dropdown {
    border-radius: 0.25rem;
  }

  .dropdown-toggle {
    border-radius: 0.25rem;
    border-color: $light-grey-200;
    background: $white;
  }

  .bootstrap-select > .dropdown-toggle {
    white-space: normal;
  }
}

.control-label > strong {
  font-weight: normal;
}

.bottom-border {
  border-bottom: 2px solid #dee2e6;
}

.wrapped-element {
  position: relative;
  display: table;
  border-collapse: separate;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

@import "theme/components/table";
@import "theme/components/editable-value";
