.z .page-header {
  background: url("~/src/assets/img/logo-white.png") no-repeat center top;
  padding-top: 75px;
  background-size: 75px 75px;
}

.navbar-header .navbar-brand {
  background: inherit;
  padding-left: 0.5rem;
  font-size: medium;
  display: flex;
  align-items: center;
}

.navbar-header .navbar-brand img {
  max-width: 1.4rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.navbar-header .navbar-brand .brand-name {
  font-size: 1.25rem;
  font-weight: 700;
}

.flex-fill .navbar-nav {
  display: inline-block;

  @include media-breakpoint-down(sm) {
    & {
      display: flex;
    }
  }

  li {
    display: inline-block;
  }
}

.admin-nav-user-context .dropdown-menu {
  right: 0;
  left: auto;
}
