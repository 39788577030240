.em-view-listView .entity-query-filter-form {
  [data-em-button-role="search"] {
    display: none;
  }

  .form-group {
    .js-form-datetimepicker {
      flex-wrap: unset;

      .datetimepicker-input {
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        &:focus {
          box-shadow: none;
          border-color: #ced4da;
        }
      }

      .input-group-append {
        .input-group-text {
          background-color: transparent !important;
          border-left: none;
          border-top-right-radius: $border-radius !important;
          border-bottom-right-radius: $border-radius !important;
        }
      }
    }

    &.active {
      input {
        color: $white;
      }

      .js-form-datetimepicker {
        .input-group-append .input-group-text {
          border-color: $primary;
        }
      }
    }
  }
}

.entity-query-filter-form {
  margin-right: 0 !important;
}

.entity-query-filter-form-basic {
  .form-group {
    margin-bottom: 0.5rem !important;
    background-color: transparent !important;

    &.toggle-form-group:hover {
      background-color: transparent !important;
    }

    .custom-switch {
      display: flex;

      label {
        position: relative;
        padding-top: 0.3rem;
        padding-left: 1.5rem;
        margin-bottom: 0.3rem;
      }
    }

    &[data-em-property="textFilter"] {
      font-family: unset;

      &::after {
        font-family: "Font Awesome 5 Free";
        content: "\f002";
        color: $dark-grey;
        vertical-align: middle;
        font-weight: 600;
        font-size: 0.85rem;
        position: absolute;
        right: 15px;
        top: auto;
        bottom: auto;
      }
    }

    &:only-of-type {
      .twitter-typeahead::after {
        font-family: "Font Awesome 5 Free";
        content: "\f002";
        color: $dark-grey;
        vertical-align: middle;
        font-weight: 600;
        position: absolute;
        right: 10px;
        top: 5px;
      }
    }
  }

  border-bottom: solid 1px $light-grey-200;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.custom-switch {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
  }

  .custom-control-label::after {
    width: calc(1.75rem - 4px);
    height: calc(1.5rem - 4px);
    top: calc(0.25rem + 4px);
    left: calc(-2.25rem + 4px);
    border-radius: 0.4rem;
    background-color: #bbbbbb;
  }

  .custom-control-label::before {
    background-color: #ffffff;
    height: 1.75rem;
    width: 3.25rem;
    border: solid 1px #e0e0e0;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.25rem);
    padding-top: 0.15rem;
    background-color: #c010c0;
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}

.em-view-listView .form-group.active .btn.dropdown-toggle *,
.em-view-listView .form-group.active input * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
