.SideNavbar {
  .MenuTitle {
    color: #888888;
    border-radius: 8px;
    text-decoration: none;
    display: block;
    padding: 0.5rem 0.75rem;
  }

  .MenuTitle:hover {
    background-color: #f8f9fa;
    span {
      background-color: #f8f9fa;
    }
  }

  .CurrentMenuTitle {
    text-decoration: none;
    color: #242424;
    background-color: #fff;
    border-radius: 8px;
    display: block;
    padding: 0.5rem 0.75rem;

    span {
      background-color: #fff;
    }
  }

  .Label {
    border: none;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0;
  }
}
