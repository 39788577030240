[data-em-entity="termsAndConditions"].em-view-formView {
  .pcs-header {
    margin-bottom: 2.5rem;
  }

  .terms-and-conditions-data-wrapper {
    padding-left: 0;
  }

  .mce-container,
  .mce-container *,
  .mce-widget,
  .mce-widget *,
  .mce-reset {
    font-family: "TTCommons", sans-serif;
  }

  .mce-edit-area > iframe {
    min-height: 100px;
  }

  .mce-ico {
    font-family: "tinymce", Arial;
  }

  .component-title {
    font-size: 1.5rem;
  }

  .component-body {
    margin-top: 1rem;
  }

  .management-form .form-group > label {
    margin-top: 0;
  }

  .form-control-static {
    border-bottom: none;
  }
}
