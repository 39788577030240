[up-close] {
  cursor: pointer; }
.up-insertion {
  display: inline-block; }
.up-bounds {
  position: absolute; }
[up-href] {
  cursor: pointer; }
.up-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  overflow-x: hidden; }

.up-modal-backdrop {
  z-index: 11000;
  background-color: rgba(90, 90, 90, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.up-modal-viewport {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 12000;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: center;
  padding: 30px 10px; }

.up-modal.up-modal-animating {
  overflow-y: scroll; }
.up-modal.up-modal-animating .up-modal-viewport {
  overflow-y: hidden; }

.up-modal-dialog {
  z-index: 13000;
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  display: inline-block;
  text-align: left; }

.up-modal-content {
  z-index: 14000;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3); }

.up-modal-close {
  z-index: 15000;
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 34px;
  color: #666;
  cursor: pointer; }

.up-modal[up-flavor='drawer'] .up-modal-viewport {
  text-align: left;
  padding: 0; }
.up-modal[up-flavor='drawer'][up-position='right'] .up-modal-viewport {
  text-align: right; }
.up-modal[up-flavor='drawer'] .up-modal-dialog {
  max-width: 350px; }
.up-modal[up-flavor='drawer'] .up-modal-content {
  min-height: 100vh;
  box-sizing: border-box; }
.up-popup {
  z-index: 20000;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3); }
.up-toast {
  border-top: 3px solid #28b;
  background-color: white;
  color: #333;
  padding: 10px;
  font-family: monospace;
  font-size: 14px;
  line-height: 15px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999; }

.up-toast-variable {
  font-weight: normal;
  color: gray; }

.up-toast-actions {
  margin-top: 7px; }

.up-toast-action {
  display: inline-block;
  word-spacing: -4px;
  text-decoration: underline;
  color: #28b;
  cursor: pointer; }
  .up-toast-action + .up-toast-action {
    margin-left: 12px; }
.up-tooltip {
  z-index: 30000;
  position: relative; }
  .up-tooltip:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border: 6px solid transparent; }
  .up-tooltip[up-position=top] {
    margin-bottom: 10px; }
    .up-tooltip[up-position=top]:after {
      border-top-color: #111;
      border-bottom-width: 0;
      bottom: -6px;
      left: 50%;
      margin-left: -6px; }
  .up-tooltip[up-position=left] {
    margin-right: 10px; }
    .up-tooltip[up-position=left]:after {
      border-left-color: #111;
      border-right-width: 0;
      right: -6px;
      top: 50%;
      margin-top: -6px; }
  .up-tooltip[up-position=right] {
    margin-left: 10px; }
    .up-tooltip[up-position=right]:after {
      border-right-color: #111;
      border-left-width: 0;
      left: -6px;
      top: 50%;
      margin-top: -6px; }
  .up-tooltip[up-position=bottom] {
    margin-top: 10px; }
    .up-tooltip[up-position=bottom]:after {
      border-bottom-color: #111;
      border-top-width: 0;
      top: -6px;
      left: 50%;
      margin-left: -6px; }

.up-tooltip-content {
  background-color: #111;
  color: white;
  padding: 6px 9px;
  white-space: nowrap; }
