.ghost {
  background: linear-gradient(
    -90deg,
    #efefef calc(50% - 5px),
    #f5f5f5 calc(50% - 5px) calc(50% + 5px),
    #efefef calc(50% + 5px) 100%
  );
  background-size: 200% 100%;
  animation: gradient 2s linear infinite;
  border-radius: 0.25rem;
}

.error {
  .ghost {
    background-color: #efefef !important;
    animation: none;
  }
}

@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
