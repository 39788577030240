.sidebar {
  //padding: 1.5rem;
  background-color: $light;

  * {
    background-color: $light;
  }

  nav + .desc {
    background-color: $light;
  }

  .card {
    border: none;

    .card-header {
      padding: 0.5rem 0.75rem;
      font-weight: bold;
      border: none;
      font-size: 0.875rem;
    }
  }

  .list-group-item {
    border: none;
    color: $dark-grey;
    padding: 0.5rem 0.75rem;

    &:last-child {
      border-radius: 8px !important;
    }

    &:hover {
      border-radius: 8px;
    }

    &.active {
      background-color: $white;
      color: $dark;
      border-radius: 8px;
    }
  }
}

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 350px;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    text-indent: 20px;
    line-height: 40px;

    a {
      display: block;
      text-decoration: none;
      color: #999999;

      &:hover {
        text-decoration: none;
        color: #fff;
        background: rgba(255, 255, 255, 0.2);
      }

      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;

    a {
      color: #999999;

      &:hover {
        color: #fff;
        background: none;
      }
    }
  }
}
