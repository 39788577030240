.Navbar {
  max-height: 4rem;
  display: flex;
  background-color: #400540;
  .MenuIcon {
    padding: 0 0.75rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }

  .SvgImage {
    width: 16px;
    height: 16px;
  }

  .MenuTitle {
    color: #ffffff;
    font-size: 20px;
    opacity: 64%;
    display: -webkit-box;
    cursor: pointer;
  }

  .CurrentMenuTitle {
    color: #ffffff;
    font-size: 20px;
    opacity: 100%;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: #c010c0;
    -webkit-text-decoration-thickness: 2px; /* Safari */
    -webkit-text-decoration-color: #c010c0; /* Safari */
    text-underline-offset: 0.375rem;
    display: -webkit-box;
    cursor: pointer;
  }

  .MenuTitle:hover {
    opacity: 100%;
  }

  .Menu {
    height: 100%;
    padding: 1rem 1.375rem;
    cursor: pointer;
  }

  .RightMenu {
    height: 100%;
    padding: 1rem 1.375rem;
    float: right;
    margin-left: auto;
    display: flex;
  }

  #NotificationBell {
    margin-right: 2rem;
  }

  .NotificationItemDivider {
    width: 32px;
    height: 0;
    opacity: 0.16;
    border: 1px solid #ffffff;
    transform: rotate(90deg);
    position: absolute;
    top: 1rem;
    right: 0;
  }

  .SectionContainer {
    position: relative;
  }

  .GeneralMenu {
    display: flex;
  }

  .dropdown-menu.show {
    left: auto;
    right: 0;
    margin-left: -1rem;
    z-index: 10000;

    .dropdown-item {
      .DropdownItemText {
        opacity: 72%;
        border: none;
      }
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      .DropdownItemText {
        opacity: 100%;
      }
    }

    .dropdown-item:active {
      color: #212529 !important;
    }
  }

  .GroupCount {
    color: #fff;
    vertical-align: middle;
    border-radius: 12px;
    margin-left: 0.5rem;
    padding: 3px 6px 2px;
    font-size: 0.75rem;
    font-weight: 400;
    background-color: #4b89f6 !important;
  }

  .dropdown-toggle:after {
    display: none;
  }

  .MenuTitle.dropdown-toggle:after,
  .CurrentMenuTitle.dropdown-toggle:after {
    vertical-align: 0;
    font-family: "Font Awesome 5 Pro";
    font-size: 0.75rem;
    content: "\f078";
    border: 0;
    margin-left: 0;
    margin-top: 0;
    padding-left: 0.5rem;
    display: inline-block;
    -webkit-transform: translateY(-12.5%);
    -moz-transform: translateY(-12.5%);
    -ms-transform: translateY(-12.5%);
    transform: translateY(-12.5%);
  }

  .DropDownSubLabel {
    opacity: 72%;
    letter-spacing: 1px;
    border: none;
    height: fit-content;
    margin-top: -8px;
    font-weight: 600;
    font-size: 12px;
  }

  .UserMenuItem {
    margin-top: -4px;
  }

  .NoBorder {
    text-decoration: none !important;
    display: inline-block;
  }
}
