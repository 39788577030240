.management-form {
  .management-placeholder {
    border-bottom: 2px solid #c010c0;
  }

  .form-group:not(.form-label-group) {
    display: flex;
    flex-wrap: wrap;

    > label {
      flex: 0 0 25%;
      max-width: 25%;
      //color: #888888;
      margin-top: 0.5rem;
      margin-bottom: 0;
    }

    p.form-control-static {
      border-bottom: none !important;
      margin-top: 0.5rem;
    }

    div.multiple-company-value {
      border-bottom: none !important;
      margin-top: 0.5rem;
    }

    .form-control-static {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      padding-bottom: 10px;
      border-bottom: 1px #e0e0e0 solid;
      margin-bottom: 0;
    }

    input,
    .bootstrap-select,
    select,
    .bootstrap-tagsinput,
    > .input-group {
      flex: 0 0 70%;
    }

    .bootstrap-tagsinput {
      background-color: transparent !important;
    }

    > .invalid-feedback {
      padding-left: 25%;
      width: 100%;
    }

    .form-text {
      padding-left: 25%;
      width: 100%;
    }

    .editable-value-wrapper {
      min-width: 70%;
    }

    .editable-value-form {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
