.btn {
  .fa-plus {
    margin-right: 0.5em;
    font-size: 0.8em;
  }
}

.btn-fix {
  padding: 0;
  border: none;
  white-space: normal;
  color: black;
  text-align: left;
}

.btn-link {
  cursor: pointer;
}

.btn-light {
  color: $dark-grey;
}

#btn-cancel {
  color: $dark-grey;
  background-color: $gray-200;
  border-color: $gray-200;

  &:hover {
    background-color: darken($gray-200, 7.5%);
    border-color: darken($gray-200, 7.5%);
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

.em-form-actions {
  .btn {
    margin-right: 0.5rem;
  }

  .btn.float-right {
    margin-right: 0;
    margin-left: 0.5rem;
  }
}
