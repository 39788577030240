/**
 * Editable value styling.
 */
_ {
  // hide the generated controls, probably a bug
  display: none;
}

.editable-value-value {
  cursor: pointer;
}

.editable-value-actions {
  position: absolute;
  right: 3px;
  background-color: $white;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  white-space: nowrap;
  padding: 2px;

  .btn {
    cursor: pointer;
    padding: 4px;
    margin-right: 0;

    &:hover {
      background-color: $light-grey-100;
      border-radius: 8px;
    }
  }

  a.btn {
    color: $dark;

    &[data-action="save"]:hover {
      color: $success;
    }

    &[data-action="cancel"]:hover {
      color: $danger;
    }
  }
}

h3 .editable-value-form input.form-control {
  color: $primary;
  font-size: 48px;
  max-width: 75rem;
}

.editable-value-form {
  min-width: 100px;
  max-width: fit-content;
  max-width: -moz-max-content;

  .editable-value-control {
    display: inline-block;
    position: relative;
  }

  .invalid-feedback {
    display: block;
    margin-left: 1rem;
    font-size: 0.8rem;
    font-weight: normal;
    color: $danger;
    vertical-align: bottom;
  }

  .form-control.is-invalid {
    background-image: none;
    padding-right: inherit;
  }

  input.form-control {
    min-width: 100px;
  }
}

.rate-card-inline-editable {
  .editable-value-wrapper {
    border-bottom: 2px solid;

    &:hover {
      cursor: pointer;
    }

    .editable-value-value::after {
      font-family: "Font Awesome 5 Free";
      content: "\f303";
      margin-left: 0.5rem;
      color: $dark-grey;
      vertical-align: middle;
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
}

.form-group {
  .editable-value-wrapper {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .editable-value-value {
    display: inline-block;
    min-width: 100px;
    min-height: 1.5rem;
    clear: both;

    &:hover:after {
      font-family: "Font Awesome 5 Free";
      content: "\f303";
      margin-left: 0.5rem;
      color: $dark-grey;
      vertical-align: middle;
      font-weight: 600;
      font-size: 0.75rem;
    }

    &:empty:hover:after {
      margin-left: 0;
    }
  }

  .editable-value-wrapper.editable-value-reload {
    .editable-value-value:hover:after {
      content: "\f1ce";
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;

  .editable-value-value:hover:after {
    font-family: "Font Awesome 5 Free";
    content: "\f303";
    font-size: 0.875rem;
    margin-left: 0.5rem;
    color: $dark-grey;
    vertical-align: middle;
    font-weight: 600;
  }

  .editable-value-wrapper.editable-value-reload {
    .editable-value-value:hover:after {
      content: "\f1ce";
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
    }
  }
}

.editable-value-control {
  .js-form-datetimepicker {
    flex-wrap: unset;

    .input-group-append {
      .input-group-text {
        background: transparent;
        border: unset;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-bottom: 2px solid $primary;
      }
    }
  }
}
