.bootstrap-tagsinput {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  height: fit-content;
  height: -moz-max-content;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 48px;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  flex-wrap: wrap;
  position: relative;

  .tag [data-role="remove"]:hover {
    box-shadow: none;
  }

  .twitter-typeahead {
    display: flex !important;
    position: unset !important;

    .tt-input {
      width: fit-content !important;
      max-width: none !important;
      min-height: unset;
    }

    .tt-hint {
      display: none;
    }

    .tt-menu {
      padding-left: 10px;
    }
  }

  .label-info {
    background-color: $secondary;
    border-radius: 16px;
    padding: 3px 10px 2px 10px;
    margin: 0.25rem;
    display: inline;
    white-space: nowrap;
  }
}

.bootstrap-tagsinput-empty-message {
  i {
    cursor: pointer;
  }
}
