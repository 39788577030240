.selected-asset {
  border: 2px solid blue;
}

.selectable-asset {
  cursor: pointer;
}

img.asset-image-preview {
  max-width: 500px;
  max-height: 250px;
}

.asset-wrapper {
  background-color: white;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 2px;
  margin-bottom: 20px;

  .dropdown-overflow {
    background-color: $light-grey-200;

    .btn {
      color: $white;
    }
  }

  &.not-approved {
    border-left: 5px solid $dark-grey;
  }

  &.no-asset,
  &.not-approved {
    border-color: $danger;
  }

  &.awaiting-approval {
    border-color: $warning;
  }

  &.approved {
    border-color: $success;
  }

  .upload-btn {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-right: 0;
  }
}

.asset-actions {
  padding-left: 0 !important;
}

.asset-dropdown-btn {
  padding: 0 5px;
}

.stretched-asset-container {
  .asset-overview {
    .fa-music {
      font-size: 1em !important;
    }

    .warning-modal {
      margin-left: 5px;
    }

    .asset-icon {
      left: 12rem !important;
    }

    .asset-thumbnail {
      min-height: 3rem !important;
      max-height: 3rem !important;
    }

    &.row {
      padding: 0 !important;
    }
  }

  .remove-btn,
  .comment-btn,
  .unlink-asset-btn,
  .link-asset-btn,
  .locked-unlink-asset-btn {
    bottom: 10px !important;
  }

  .asset-dropdown-btn {
    bottom: -9px !important;
  }

  .row {
    display: flex;
    flex-direction: row;

    .col-5:first-child {
      flex: 0;
    }

    .col-1 {
      display: flex;
      flex-direction: row;

      .asset-icon {
        align-self: center;
      }
    }

    .col-5:nth-child(2) {
      display: flex;
      flex-direction: row;

      .asset-title {
        min-width: 75%;
        align-self: center;
        padding-right: 1rem;
      }

      .asset-detail-text {
        min-width: 40%;
        align-self: center;
        padding-right: 3rem;
      }
    }
  }
}

.asset-container {
  .asset-overview {
    &.failed-asset {
      background-color: $danger;
      color: $white;

      .failed-asset-img {
        background-color: rgba(0, 0, 0, 0.2);
      }

      .asset-detail-text {
        color: $light-grey-200;
        font-size: 12px;
        font-weight: 300;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .asset-info-text {
        font-size: 14px;
      }

      .delete-btn,
      .asset-btn {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    border-radius: 8px;
    background-color: $light;

    .asset-thumbnail {
      min-height: 6rem;
      max-height: 6rem;
      height: 100%;
      object-fit: contain;
      border-radius: 5px;
      padding: 10px;
    }

    .asset-progress {
      background-color: $light-grey-200;
    }

    .asset-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .asset-detail-text {
      font-size: 14px;
    }

    .delete-btn,
    .asset-btn {
      padding: 0 5px;
      margin-left: 0.5rem;
    }

    .asset-dropdown {
      margin-left: 0.5rem;

      .dropdown-menu {
        padding-left: 20px;
        padding-right: 20px;
      }

      .dropdown-item {
        padding-left: 5px;
        padding-right: 5px;
        border-bottom: 1px #e0e0e0 solid;

        &:active {
          background-color: transparent;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }

      .dropdown-link-btn {
        &::before {
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          margin-right: 8px;
          font-size: 11px;
          content: "\f0c1";
        }
      }

      .dropdown-group-btn {
        &::before {
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          margin-right: 8px;
          font-size: 11px;
          content: "\f0c1";
        }
      }

      .dropdown-delete-btn {
        &::before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          margin-right: 8px;
          font-size: 11px;
          content: "\f1f8";
        }
      }

      .asset-dropdown-btn {
        padding: 0 5px;

        &:focus {
          border-color: $primary;
          background-color: $primary;
          box-shadow: none;
        }
      }
    }

    .remove-btn,
    .comment-btn,
    .tracking-btn,
    .unlink-asset-btn,
    .link-asset-btn,
    .locked-unlink-asset-btn {
      padding-top: 1px;
      padding-bottom: 1px;
      font-size: 15px;
      margin-left: 0.5rem;

      &::before {
        font-family: "Font Awesome 5 Pro";
        color: $white;
        font-weight: 900;
        margin-right: 5px;
        font-size: 11px;
      }
    }

    .comment-btn {
      &::before {
        content: "\f075";
      }
    }

    .unlink-asset-btn,
    .locked-unlink-asset-btn {
      &::before {
        content: "\f127";
      }

      &::after {
        font-family: "Font Awesome 5 Pro";
        content: "\f054";
        color: $white;
        font-weight: 900;
        margin-left: 5px;
        font-size: 11px;
      }
    }

    .link-asset-btn {
      &::before {
        content: "\f0c1";
      }
    }
  }

  .assets-link {
    i {
      vertical-align: middle;
    }
  }
}

.asset-upload-button {
  position: relative;
  float: right;
  margin-top: 20px;
  font-size: 15px;
  z-index: 1;

  &::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f341";
    color: $white;
    font-weight: 900;
    margin-right: 10px;
    font-size: 11px;
  }
}

.warning-modal {
  cursor: pointer;
  margin-left: -8px;
  padding: 0 5px;
  background-color: #ffe4c4;
}
