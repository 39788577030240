h3 {
  font-weight: $font-weight-bold;
}

.page-header {
  color: $primary;
  font-size: 48px;
  margin-bottom: 0;

  small {
    font-size: 1rem;
    font-weight: 500;
  }
}
