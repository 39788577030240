.mark-asset-form {
  .row {
    display: flex;
    align-items: center;
  }

  .custom-urgency-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ff5050;
    font-family: "Font Awesome 5 Free";
    content: "\f06d";
    color: #ffffff;
    font-weight: 600;
  }

  .bootstrap-tagsinput {
    background-color: transparent;

    .label-info {
      background-color: $gray-200;
      color: $black;

      &.plus-tag {
        background-color: $primary;
        color: $white;
      }
    }
  }

  input[type="text"] {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    background-color: transparent;
  }

  .mark-asset-overview {
    max-height: 54px;
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;

    .asset-name {
      font-size: 0.9rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .asset-icon {
      padding-top: 0.15rem;
    }

    .asset-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .left-border {
      content: "";
      position: absolute;
      display: block;
      height: 54px;
      width: 4px;
      top: 0;
      left: 16px;
      z-index: 1;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    img {
      height: 50px !important;
    }
  }

  .asset-status {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .form-group .custom-urgency-switch label {
    padding-top: 0.3rem;
    padding-left: 1.5rem;
    margin-bottom: 0.3rem;
  }
  .invalid-tag-input {
    color: $white !important;
    background-color: $red !important;
  }
}
