.bottom-blade-container {
  &.show {
    height: 60px;
  }

  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: $white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
