.filter-blade-container {
  display: flex;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  margin-right: -250px;
  overflow: visible;
  background: $white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &.show {
    width: 320px;
  }

  .em-list-form {
    display: flex;
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    overflow-y: scroll;

    .row {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .filter-blade-filter-form {
    display: flex;
    width: 100%;

    .filter-blade-filter-form-basic {
      display: flex;
      flex-direction: column;
      padding: 2rem 1.5rem;
      width: 100%;

      :nth-last-child(2) {
        margin-top: auto;
      }

      .form-group {
        position: relative;
        background-color: white;
        border-radius: 8px;
        margin-bottom: 0.5rem;

        input,
        .input-group {
          font-size: 16px;
          outline: 0;
          border-radius: 8px !important;
          padding-bottom: 0;
        }

        input {
          background-color: transparent !important;
          padding-top: 10px;
        }

        .dropdown,
        button {
          border-radius: 8px !important;
          background-color: transparent !important;

          &:hover {
            background-color: transparent !important;
          }
        }

        label {
          position: absolute;
          color: $dark-grey;
          font-size: 16px;
          transform-origin: top left;
          transform: translate(12px, 0px) scale(1);
          transition: all 0.1s ease-in-out;
          pointer-events: all;
          margin-top: 0.45rem;

          &.title-label {
            display: block;
            font-size: 1.5rem;
            position: relative;
            transform: none;
          }
        }

        &.active {
          background-color: unset !important;

          [data-entity-query-property] {
            background-color: $primary !important;
          }

          input {
            color: $white;
          }

          label:not(.title-label) {
            color: $white;
          }
        }

        &.active,
        &.focus {
          label:not(.title-label) {
            transform: translate(12px, -8px) scale(0.7);
          }

          .filter-option-inner {
            display: inline-block;
          }
        }

        .filter-option {
          margin-bottom: -11px;
        }

        .filter-option-inner {
          display: none;
        }

        .filter-option-inner-inner {
          color: $black;
        }
      }

      .multi-value-autosuggest-selected {
        display: flex;

        tbody {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: start;
          background-color: transparent;
          box-shadow: none;

          tr {
            display: flex;
            align-items: center;
            background-color: #03a9f4;
            color: #fff;
            border-radius: 8px;
            padding: 0.35rem 0.5rem;
            border: none;
            margin: 0.25rem 0.25rem 0 0;

            &:before,
            &:after {
              content: unset;
            }

            td {
              display: flex;
              font-size: 0.75rem;
              color: white;
              padding: 0;
              border-top: none;

              &.row-actions {
                margin: 0;
                padding-left: 0.2rem;

                a {
                  display: flex;
                  text-decoration: none;

                  i {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#filter-blade-control {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 25%;
  background-color: $primary;
  color: $white;
  padding: 0.75rem 1.35rem;
  font-size: 1.5rem;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  cursor: pointer;
}
