.breadcrumb {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 0;
  padding-top: 0;

  .breadcrumb-item {
    color: $dark-grey;
    margin: 0.75rem 0;
  }

  .breadcrumb-spacer {
    position: relative;
    width: 2rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}

.chevron {
  height: 3rem;
  width: 2rem;
  position: absolute;
  overflow: hidden;
}
.chevron::before,
.chevron::after {
  content: "";
  width: 1.875rem;
  height: 1px;
  background: #dcdcdd;
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.chevron::before {
  transform-origin: 50%;
  top: 2.25rem;
  transform: rotate(-60deg);
}
.chevron::after {
  transform-origin: 50% 50%;
  transform: rotate(60deg);
  top: 22%;
}
.badge-wrapper {
  align-items: center;
  display: flex;

  .GroupCount {
    color: #fff;
    vertical-align: middle;
    border-radius: 12px;
    margin-left: 0.5rem;
    padding: 0px 6px 0px;
    font-size: 0.75rem;
    font-weight: 400;
    background-color: #4b89f6 !important;
  }
}
