.company-switch-warning-container {
  max-width: 40rem;
  min-height: 100%;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #ffffff;
  box-shadow:
    0 0 1px 1px rgba(0, 0, 0, 0.05),
    0 1px 0 rgba(0, 0, 0, 0.06),
    0 10px 32px rgba(0, 0, 0, 0.06),
    0 0 0 rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  justify-content: center;
  text-align: center;
  margin-top: 5rem;

  button {
    min-width: 4rem;
  }
}
