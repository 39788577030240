.progressbar {
  counter-reset: step;

  a {
    list-style-type: none;
    width: calc(100% / 5);
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    color: #7d7d7d;

    &:before {
      width: 30px;
      height: 30px;
      content: counter(step);
      counter-increment: step;
      line-height: 30px;
      border: 2px solid #7d7d7d;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
    }

    &:after {
      width: 100%;
      height: 2px;
      content: "";
      position: absolute;
      background-color: #7d7d7d;
      top: 15px;
      left: -50%;
      z-index: -1;
    }

    &:first-child:after {
      content: none;
    }

    &.active {
      color: green;
      &:before {
        border-color: #55b776;
      }

      &:hover {
        color: #55b776;
      }
    }

    &.current {
      color: #29a5e3;

      &:before {
        border-color: #29a5e3;
      }

      &:hover {
        color: #29a5e3;
      }
    }

    &:hover {
      text-decoration: none;
      color: #7d7d7d;
    }
  }

  a.active + a:after {
    background-color: #55b776;
  }
}
