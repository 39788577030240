.vjs-has-started .vjs-poster {
  // Hide the thumbnail when the video is playing
  display: none;
}

.vjs-ended > .vjs-poster {
  // Show the thumbnail when the video has stopped
  display: inline-block;
}

.video-js {
  .vjs-big-play-button {
    // No initial huge play button, we show the control bar instead
    display: none;
  }

  .vjs-play-control {
    background-color: $primary;
    border-radius: $border-radius;
  }

  .vjs-control-bar {
    // A 'floating' control bar on the bottom of the video
    width: 95%;
    left: 10px;
    bottom: 10px;
    display: flex;
    background-color: transparent;
  }

  //.vjs-volume-panel {
  //  display: none;
  //}
  .vjs-time-control {
    color: $gray-500;
    background-color: $white;
    border-radius: $border-radius;
    margin-right: 1em;
  }

  .vjs-fullscreen-control {
    color: $gray-500;
    background-color: $white;
    border-radius: $border-radius;
  }
}
