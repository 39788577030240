input[type="checkbox"].check-mark {
  -moz-appearance: initial;
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  left: 15px;

  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: $white;
    line-height: 20px;
    text-align: center;
    vertical-align: center;
    border: 1px solid $light-grey-200;
    left: -15px;
  }

  &:checked:after {
    border: 1px solid $primary;
    background-color: $primary;
    color: $white;
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    font-weight: 600;
    font-size: 0.75rem;
  }

  &.partially-checked:after {
    border: 1px solid $primary;
    background-color: $primary;
    color: $white;
    font-family: "Font Awesome 5 Free";
    content: "\f068";
    font-weight: 600;
    font-size: 0.75rem;
  }
}

.check-mark-label {
  position: relative;
  left: 25px;
}
