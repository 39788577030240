.js-form-datetimepicker {
  .datetimepicker-input {
    border-right: none;
  }

  .input-group-append {
    .input-group-text {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
      background-color: $white;
    }
  }
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: $primary;
}

.ui-datepicker {
  border: 1px solid $info;
  background-color: $white;
  border-radius: 0.25rem !important;
  padding: 0.5rem;

  .ui-datepicker-header {
    a {
      cursor: pointer;

      &[data-handler="prev"] {
        &:before {
          content: "\f053";
          font-family: "Font Awesome 5 Free";
          font-weight: 600;
          padding-right: 0.25rem;
        }
      }

      &[data-handler="next"] {
        float: right;

        &:after {
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          font-weight: 600;
          padding-left: 0.25rem;
        }
      }
    }

    .ui-datepicker-title {
      display: flex;
      justify-content: center;
      font-weight: bold;
    }
  }
}

.ui-datepicker-calendar {
  th {
    font-weight: normal;
    text-align: center;
  }

  td {
    padding: 0.35rem;
    text-align: center;

    &.ui-datepicker-today {
      border: 2px solid black;
      background-color: #0075ff;

      &:hover {
        background-color: #0075ff;
      }

      a {
        color: white;
      }
    }

    &:hover {
      background-color: #b2d5ff;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      color: black;
    }
  }
}
