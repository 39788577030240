#admin-top-navbar {
  z-index: 2;
}

.top-nav {
  height: 64px;
  flex-shrink: 0;
  background-color: #400540 !important;
  font-size: 1.25rem;

  .navbar-dark {
    background-color: #400540 !important;
  }

  .nav-link,
  .nav-item {
    &.active {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $primary;
        height: 4px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        margin-bottom: -9px;

        @include media-breakpoint-down(md) {
          & {
            content: none;
          }
        }
      }
    }
  }

  .navbar-nav .nav-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    i {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }

  .nav-item.dropdown {
    margin-left: 0.3rem;
    margin-right: 0.3rem;

    @include media-breakpoint-down(md) {
      & {
        margin-left: 0.5rem;
      }
    }

    &.user-icon {
      margin-left: 2rem;
      font-size: 1.33333em;
      line-height: 0.75em;
      vertical-align: -0.0667em;

      @include media-breakpoint-down(md) {
        & {
          margin-left: 0.5rem;
        }
      }

      &::before {
        content: "";
        width: 0;
        height: 60%;
        position: absolute;
        border: 0.5px solid $dark-grey;
        opacity: 50%;
        top: 20%;
        left: -15px;

        @include media-breakpoint-down(md) {
          & {
            content: none;
          }
        }
      }
    }

    .dropdown-menu.show {
      right: 0;
      left: auto;
      margin-top: 14px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .dropdown-toggle::after {
    border: solid gray;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .dropdown-toggle.active::after {
    position: relative;
    background-color: transparent;
    top: auto;
    bottom: auto;
    margin-bottom: 0;
  }
}

.top-nav-2 {
  height: 0;
  flex-shrink: 0;
  padding: 0 1rem;
  font-size: 1.15rem;
  transition:
    padding 200ms ease-in-out,
    height 200ms ease-in-out;
  overflow: hidden;
  border: none;

  &.visible {
    height: 64px;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #dee2e6 !important;
  }

  .top-nav-pill {
    background-color: #4b89f6;
    color: #ffffff;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 1rem;
    padding: 0.2rem 0.4rem;
  }

  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $dark;
        height: 4px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        margin-bottom: -10px;

        @include media-breakpoint-down(md) {
          & {
            content: none;
          }
        }
      }
    }
  }

  .navbar-nav .nav-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    i {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }

  .nav-item.dropdown {
    margin-left: 0.3rem;
    margin-right: 0.3rem;

    @include media-breakpoint-down(md) {
      & {
        margin-left: 0.5rem;
      }
    }

    &.user-icon {
      margin-left: 2rem;
      font-size: 1.33333em;
      line-height: 0.75em;
      vertical-align: -0.0667em;

      @include media-breakpoint-down(md) {
        & {
          margin-left: 0.5rem;
        }
      }

      &::before {
        content: "";
        width: 0;
        height: 60%;
        position: absolute;
        border: 0.5px solid $dark-grey;
        opacity: 50%;
        top: 20%;
        left: -15px;

        @include media-breakpoint-down(md) {
          & {
            content: none;
          }
        }
      }
    }

    .dropdown-menu.show {
      right: 0;
      left: auto;
      margin-top: 14px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .dropdown-toggle::after {
    border: solid gray;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .dropdown-toggle.active::after {
    position: relative;
    background-color: transparent;
    top: auto;
    bottom: auto;
    margin-bottom: 0;
  }
}

@media only screen and (max-height: 800px) {
  .top-nav-2 {
    &.visible {
      height: 40px;
      padding: 0.5rem 1rem;
    }

    .nav-item {
      font-size: 1rem;

      .nav-link.active:after {
        margin-bottom: 1px;
      }
    }
  }

  .top-nav {
    height: 40px;
    font-size: 1rem;

    .nav-link {
      padding: 0;
    }

    .nav-item {
      font-size: 1rem;
    }

    .nav-link.active:after,
    .nav-item.active:after {
      margin-bottom: -8px;
    }

    .dropdown-toggle {
      min-height: 24px;
    }
  }
}
