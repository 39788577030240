.campaign-container {
  border-bottom: solid 1px $light;

  .campaign-title {
    margin-top: 7px;
    color: $dark;
    font-size: 16px;
    line-height: 1.25;
  }

  .campaign-overview a:hover {
    text-decoration: none;
  }

  .campaign-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.25);
  }

  .campaign-company {
    font-size: 12px;
    line-height: 1.67;
  }

  .campaign-icons {
    .campaign-icon {
      color: white;
      background-color: $dark-grey;
      border-radius: 10px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 2px;
      width: 50px;
      font-size: 0.65rem;

      i {
        margin-left: 5px;
        margin-right: 4px;
      }
    }
  }

  .campaigns-link {
    i {
      vertical-align: middle;
    }
  }
}
