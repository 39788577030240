.nav-tabs {
  .nav-item .nav-link {
    border: none;
    color: $dark-grey;
    padding-bottom: 17px;

    &:hover {
      color: $black;
    }

    &.disabled {
      color: $light-grey-200;
    }
  }

  .nav-item {
    &:only-child {
      visibility: hidden;
      max-height: 20px;
    }

    &:first-of-type .nav-link {
      padding-left: 0;

      &.active::after {
        left: 0;
        width: calc(100% - 17px);
      }
    }

    .nav-link.active {
      color: $black;
      position: relative;
      background-color: transparent;
      border-bottom: 1px solid #dee2e6;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 0;
        width: calc(100% - 30px);
        background: $black;
        height: 4px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        margin-bottom: -0.5px;
      }
    }
  }
}

.pcs-body-section .tab-content.px-3 {
  padding-left: 0 !important;
}
