.activity-timeline-line {
  width: 0;
  height: 100%;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
}

.activity-timeline-icon {
  position: absolute;
  transform: translateX(-50%);
}
