.image-wrapper {
  min-height: 250px;
  position: relative;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.description-wrapper {
  min-height: 200px;
}

.card-body {
  padding: 0.25rem 1.25rem 0.1rem;
}

.offer-image {
  margin-top: 1rem;
  width: 100%;
  height: 150px;
  line-height: 115px;
  text-align: center;
  max-height: 200px;

  img {
    float: left;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
}

.campaign-state-select .dropdown-item {
  min-height: 32px;
}

.campaign-details [data-em-property="campaignState"] sup.required {
  display: none;
}
