.modal-audio-detail {
  max-width: 97.5%;
  margin: 10px auto;

  .modal-content {
    padding-left: 30px;
    padding-right: 30px;
    background-color: $light-grey-100;

    .close {
      font-size: small;
      right: 20px !important;
      top: -5px !important;
    }

    .wave-play-button {
      max-width: 30px;
      max-height: 30px;
    }

    .wave cursor {
      border-right: 1px solid $primary !important;
    }

    .audio-detail-wave-surfer {
      background-color: $white;
      padding-top: 20px;
      border-radius: $border-radius;
      padding-left: 20px;
      padding-right: 20px;
    }

    .table-wrapper {
      background-color: $white;
      border-radius: $border-radius;
      margin-top: 10px;
      margin-bottom: 20px;

      .table td,
      .table th {
        padding: 0;
      }

      table {
        border: none;
        border-collapse: collapse;
      }

      table th,
      td {
        border-left: 1px solid $light-grey-200;
      }

      table th:first-child,
      td:first-child {
        border-left: none;
      }

      .table-borderless {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .actions {
      margin-left: 0;
    }
  }
}
