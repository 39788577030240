@mixin theme-table-row-variant($state, $background, $border) {
  tr.table-#{$state},
  tr.table-#{$state}.odd,
  tr.table-#{$state}.even {
    background-color: $background;

    &:before {
      border-left: solid 4px $border;
    }

    &,
    > th,
    > td {
      background-color: $background;
    }
  }
}
