.background-overlay {
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  display: block;
  right: 0;
  bottom: 0;
  width: 0;
  background-color: transparent;
  z-index: 2;
  -webkit-transition: background-color 0.5s ease;
  -moz-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  cursor: unset;

  &.show {
    width: 100%;
    background-color: rgba(192, 16, 192, 0.45);

    &.transparent {
      background-color: transparent !important;
    }
  }
}
