form.create-new-campaign {
  .form-group[data-em-property] {
    label {
      position: absolute;
      color: $dark-grey;
      transform-origin: top left;
      transform: translate(12px, 0px) scale(1);
      transition: all 0.1s ease-in-out;
      pointer-events: all;
      padding-left: 0;
    }

    &.selecting-suggestion,
    &.focus {
      label {
        transform: translate(12px, -8px) scale(0.7);
      }
    }

    &.disabled {
      label {
        z-index: 1;
      }
    }

    .axbum-typeahead {
      background-color: transparent;

      input[type="search"] {
        padding-top: 1rem;
        height: 50px;
      }

      .twitter-typeahead,
      input:not([disabled]) {
        background-color: transparent;
      }

      .tt-suggestion:hover {
        background-color: $primary;
      }
    }
  }
}
