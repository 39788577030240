.dropdown-header {
  padding: 0.25rem 0.5rem;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  padding: 0.25rem 0.5rem;
}

.dropdown-item {
  border-radius: 0.5rem;
  transition: all linear 0.2s;

  &:hover {
    background-color: rgba(75, 137, 246, 0.08);
  }
}

.dropdown-header-actions {
  margin-left: auto;
  padding-left: 1rem;
  font-weight: 400;
  font-size: 16px;
  gap: 1rem;

  a {
    margin-left: 1rem;
    text-decoration: underline !important;
    transition: all linear 0.2s;
    color: rgba(34, 36, 41, 0.64);

    svg {
      position: relative;
      top: 0.125rem;
    }

    &:hover {
      color: #222429 !important;
    }
  }
}
