.register-highlight {
  color: #03a9f4;
}
.register-confirm-content {
  font-family: TTCommons;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #888888;
}
.register-confirm-title {
  font-family: TTCommons;
  font-size: 64px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
}

#modifyLaterMessage {
  display: block;
  margin-top: -0.25rem;
}
