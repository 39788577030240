@font-face {
  font-family: "TTCommons";
  src: url("~/src/assets/fonts/TTCommons-Book.eot");
  src:
    url("~/src/assets/fonts/TTCommons-Book.eot?#iefix") format("embedded-opentype"),
    url("~/src/assets/fonts/TTCommons-Book.woff2") format("woff2"),
    url("~/src/assets/fonts/TTCommons-Book.woff") format("woff"),
    url("~/src/assets/fonts/TTCommons-Book.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "TTCommons";
  src: url("~/src/assets/fonts/TTCommons-Regular.eot");
  src:
    url("~/src/assets/fonts/TTCommons-Regular.eot?#iefix") format("embedded-opentype"),
    url("~/src/assets/fonts/TTCommons-Regular.woff2") format("woff2"),
    url("~/src/assets/fonts/TTCommons-Regular.woff") format("woff"),
    url("~/src/assets/fonts/TTCommons-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "TTCommons";
  src: url("~/src/assets/fonts/TTCommons-Medium.eot");
  src:
    url("~/src/assets/fonts/TTCommons-Medium.eot?#iefix") format("embedded-opentype"),
    url("~/src/assets/fonts/TTCommons-Medium.woff2") format("woff2"),
    url("~/src/assets/fonts/TTCommons-Medium.woff") format("woff"),
    url("~/src/assets/fonts/TTCommons-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "TTCommons";
  src: url("~/src/assets/fonts/TTCommons-DemiBold.eot");
  src:
    url("~/src/assets/fonts/TTCommons-DemiBold.eot?#iefix") format("embedded-opentype"),
    url("~/src/assets/fonts/TTCommons-DemiBold.woff2") format("woff2"),
    url("~/src/assets/fonts/TTCommons-DemiBold.woff") format("woff"),
    url("~/src/assets/fonts/TTCommons-DemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "TTCommons";
  src: url("~/src/assets/fonts/TTCommons-Bold.eot");
  src:
    url("~/src/assets/fonts/TTCommons-Bold.eot?#iefix") format("embedded-opentype"),
    url("~/src/assets/fonts/TTCommons-Bold.woff2") format("woff2"),
    url("~/src/assets/fonts/TTCommons-Bold.woff") format("woff"),
    url("~/src/assets/fonts/TTCommons-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  src: url("~/src/assets/fonts/Font-Awesome-5-Pro-Light-300.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  src: url("~/src/assets/fonts/Font-Awesome-5-Pro-Regular-400.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  src: url("~/src/assets/fonts/Font-Awesome-5-Pro-Solid-900.woff") format("woff");
  font-weight: 900;
  font-style: oblique;
}
