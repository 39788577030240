.up-modal-content {
  border-radius: 8px;

  form {
    margin-bottom: 0;
  }

  .em-form-actions {
    text-align: center;
  }
}

.up-modal-close {
  color: $dark-grey;
  background-color: $gray-200;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 1.75rem;
  height: 1.5rem;
  font-size: 1.75rem;
  line-height: 0.8;
}

.modal form {
  margin-bottom: 0;
}
