.modal-tracking-detail {
  max-width: 50%;
  margin: 10px auto;

  .modal-content {
    background-color: $white;
    min-height: 75vh;
  }

  .close {
    font-size: small;
    right: 20px !important;
    top: -5px !important;
  }

  .axu-border-top {
    border-top: none;
  }

  .embedded-collection-items {
    overflow: scroll;
    max-height: 47.5vh;

    .embedded-collection-item-data {
      margin-left: 0.25rem;
      margin-bottom: 0.25rem;

      .form-group {
        padding: 0;
        margin-bottom: 0;
      }
    }
  }

  .embedded-collection-actions {
    padding-top: 0.5rem;
  }

  .embedded-collection-item-actions {
    padding-top: 0.5rem;
  }

  .form-group {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  textarea.url-field.form-control {
    word-break: break-all;
  }

  textarea.url-field.form-control.is-invalid {
    background-image: none;
  }

  .form-control-static {
    word-break: break-all;
  }
}

@media only screen and (max-width: 1600px) {
  .modal-tracking-detail {
    .modal-content {
      min-height: 85vh;
    }
  }
}
